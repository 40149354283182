<template>
  <div>
    <div class="trfwrapper vs-con-loading__container" id="transfer-wrapper">
      <div class="cryptoremit-receiving-currency relative">
        <div id="receiving-fiat-container"
             class="money-input-form-group form-group form-group-lg mb-0">
          <label class="control-label" for="receiving-fiat-input">{{formReceiveLabel && formReceiveLabel}}</label>
          <div class="money-input input-group input-group-lg">
            <input type="text"
                   inputmode="decimal"
                   placeholder=""
                   id="receiving-fiat-input"
                   autocomplete="off"
                   class="form-control"
                   v-model="receiveAmount"
                   :class="[amountTooSmall || invalidFormat  ? 'border-error' : '']"
            >
            <div class="input-group-btn">
              <div class="btn-group btn-block">
                <button class="btn btn-group btn-light btn-receive-currency-homepage"
                        id="receiving-currency-button"
                        @click.stop="toggleReceiveCurrencyOptions()"
                        v-if="selectedReceiveCurrency">
                  <country-flag
                      :country="selectedReceiveCurrency.countryCode"></country-flag>
                  <span class="m-1 selected-receive-currency">{{ selectedReceiveCurrency && selectedReceiveCurrency.currencyCode }}</span>
                  <chevron-down-icon size="0.6x"></chevron-down-icon>
                </button>
                <ul class="dropdown-currency-list" id="dropdown-menu-receiving-currency">
                  <li class="dropdown-item-divider">
                    <a class="currency-search">
                      <div class="input-group">
                        <span class="pt-2 pb-2 pr-2">
                            <span>
                             <search-icon size="1x"
                                          class="custom-class"></search-icon>
                        </span>
                        </span>
                        <input type="text" placeholder="Enter a currency / country"
                               style="border:0" id="search-receive-currency-input"
                               class="w-100 pr-2"
                               v-model="searchReceivingCurrencyQuery"
                        >
                      </div>
                    </a>
                  </li>
                  <div v-for="item in receiveCurrencyList" :key="item.currencyCode"
                       :value="item.currencyCode">
                    <li>
                      <a class="currency-item" @click="inputReceivingCurrency(item)">
                        <country-flag :country="item.countryCode"
                                      class="mr-1"></country-flag>
                        {{ item.currencyCode }} <small class="ml-1">{{ item.currencyName }}</small>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--                <div class="form-group-homepage-addon small">-->
        <!--                    Exchange rate 1 BTC = {{ formatFiatPrice(cryptoExchangeRate) }} {{ sendCurrency }} (indicative)-->
        <!--                </div>-->
      </div>
      <div class="vertical-steps">
        <div class="step">
          <div class="plus-sign">
            +
          </div>
          <div class="content">
<!--            Transfer fee = 0 {{ receiveCurrency }} <span class="text-success">(0% fee promo until 25 May 2021)</span>-->
                                    Transfer fee = {{ formatFiatPrice(sendingTransferFee) }} {{receiveCurrency}}
<!--            (0.5% x {{ receiveAmount === "" ? 0.00 :-->
<!--                                    formatFiatPrice(receiveAmount) }}-->
<!--                                    {{ receiveCurrency }})-->
          </div>
        </div>
        <div class="step">
          <div class="content">
            <!--                        Amount we will convert = {{ formatFiatPrice(finalSendAmount) }} {{ sendCurrency }}-->
            Exchange rate {{cryptoCurrency && cryptoCurrency}}/{{receiveCurrency && receiveCurrency }} = {{ cryptoExchangeRate && formatFiatPrice(cryptoExchangeRate) }}
            (indicative)
          </div>
        </div>
      </div>


      <div class="relative mb-2">
        <div id="crypto-amount-container"
             class="money-input-form-group form-group form-group-lg mb-0">
          <label class="control-label" for="crypto-amount-input">{{formSendLabel && formSendLabel}}</label>
          <div class="money-input input-group input-group-lg">
            <input type="text"
                   inputmode="decimal"
                   placeholder=""
                   id="crypto-amount-input"
                   autocomplete="off"
                   class="form-control"
                   v-model="cryptoAmount"
                   readonly>
            <!--<p class="crypto-amount-in-input">(0.00000897 BTC)</p>-->
            <div class="input-group-btn">
              <div class="btn-block">
                <!--                            <select id="select-receiving-fiat-currency"-->
                <!--                                    class="btn btn-group btn-block btn-light"-->
                <!--                                    v-model="receiveCurrency"-->
                <!--                            >-->
                <!--                                <option v-for="item in receivingCurrencies" :value="item" :key="item">-->
                <!--                                    {{ item }}-->
                <!--                                </option>-->
                <!--                            </select>-->
                <div class="btn btn-group btn-light flex-row-align-center"
                     id="crypto-amount-button"
                     v-if="selectedReceiveCurrency && cryptoCurrency"
                     @click.stop="toggleCryptoCurrencyOptions()">
                  <!-- <cryptoicon :symbol="cryptoCurrency.toLowerCase()" size="27"/> -->
                  <template v-if="cryptoCurrency=== 'BTC'">
                    <BTC size="27" />
                  </template>
                  <template v-if="cryptoCurrency=== 'ETH'">
                    <ETH size="27" />
                  </template>
                  <template v-if="cryptoCurrency=== 'USDT'">
                    <USDT size="27" />
                  </template>
                  <template v-if="cryptoCurrency=== 'USDC'">
                    <USDC size="27" />
                  </template>
                  <template v-if="cryptoCurrency=== 'LNBC'">
                    <LBTC size="27" />
                  </template>
                  <span class="ml-1 mr-1 selected-crypto-currency">{{cryptoCurrency}}</span>

                  <chevron-down-icon size="0.5x"></chevron-down-icon>
                </div>
                <ul class="dropdown-crypto-list" id="dropdown-menu-crypto-currency">
<!--                  <li class="dropdown-item-divider">-->
<!--                      <a class="currency-search">-->
<!--                          <div class="input-group">-->
<!--                              <span class="pt-2 pb-2 pr-2">-->
<!--                                  <span>-->
<!--                                   <search-icon size="1x"-->
<!--                                                class="custom-class"></search-icon>-->
<!--                              </span>-->
<!--                              </span>-->
<!--                              <input type="text" placeholder="Enter a currency / country"-->
<!--                                     style="border:0" id="search-crypto-currency-input"-->
<!--                                     class="w-100 pr-2"-->
<!--                                     v-model="searchReceivingCurrencyQuery"-->
<!--                              >-->
<!--                          </div>-->
<!--                      </a>-->
<!--                  </li>-->
                  <!-- <div v-for="item in sendCryptoCurrencies" :key="item.cryptoCode"
                       :value="item.cryptoCode">
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency(item.cryptoCode)">
                            <cryptoicon :symbol="item.cryptoCode.toLowerCase()" size="24" class="mr-1" /> {{item.cryptoCode}} <span class="ml-1">{{item.cryptoName}}</span>
                          </a>
                      </li>
                  </div> -->
                  <div>
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency('BTC')">
                            <BTC size="24" /> <span class="ml-1">BTC</span> <span class="ml-1">Bitcoin</span>
                          </a>
                      </li>
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency('ETH')">
                            <ETH size="24" /> <span class="ml-1">ETH</span> <span class="ml-1">Ethereum</span>
                          </a>
                      </li>
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency('USDT')">
                            <USDT size="24" /> <span class="ml-1">USDT</span> <span class="ml-1">Tether</span>
                          </a>
                      </li>
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency('USDC')">
                            <USDC size="24" /> <span class="ml-1">USDC</span> <span class="ml-1">USD Coin</span>
                          </a>
                      </li>
                      <li>
                          <a class="currency-item" @click="inputCryptoCurrency('LNBC')">
                            <LBTC size="24" /> <span class="ml-1">LNBC</span> <span class="ml-1">Lightning Bitcoin</span>
                          </a>
                      </li>
                  </div>
<!--                  <div class="m-3">-->
<!--                    <span style="display: flex;align-items:center">Other cryptocurrencies are coming soon.</span>-->
<!--                  </div>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--                <div class="form-group-homepage-addon small">-->
        <!--                    Exchange rate 1 {{ sendCurrency }} = {{ formatFiatPrice(fiatExchangeRate) }} {{ receiveCurrency }}-->
        <!--                </div>-->
      </div>

      <div class="flex-col-display">
        <!--                <small class="text-error" v-if="validationSendAmountError">Send amount should be bigger-->
        <!--                    than zero.</small>-->
        <!--                <small class="text-error" v-if="validationReceiveAmountError">Receive amount should be-->
        <!--                    bigger than zero.</small>-->
        <!--                <small class="text-error" v-if="validationCryptoAmountError">Crypto amount should be-->
        <!--                    bigger than zero.</small>-->
        <small class="text-error" v-if="amountTooSmall">Receive amount is too small.</small>
        <small class="text-error" v-else-if="invalidFormat">Only enter numeric characters for receive amount.</small>
      </div>

      <div class="pt-3">
        <button class="btn btn-success btn-block mb-2"
                :disabled="amountTooSmall || invalidFormat"
                :class="[urlPathName === '/home' ? 'gtm-continue' : 'gtm-continue-send-money']" type="button"
                @click="submitForm()">
          {{
            window.location.href.indexOf('account/register') > 0 ? "Register" :
                window.location.href.indexOf('account/login') > 0 ? "Login" :
                    "Continue"
          }}
        </button>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from 'vue';
import {mapGetters} from "vuex";
import BigNumber from "bignumber.js";
import {mapActions} from "vuex";
import CountryFlag from 'vue-country-flag'
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'

import Cryptoicon from 'vue-cryptoicon';
import icons from 'vue-cryptoicon/src/icons';
Cryptoicon.add(icons);
Vue.use(Cryptoicon);

import {debounce} from '../helpers/helpers'

import { BTC, ETH, USDT, USDC, LBTC } from './icons'

// // Vuesax Component Framework
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'; // Vuesax
// Vue.use(Vuesax);

export default {
  name: 'transcrypt-transfer-preparation',
  components: {
    CountryFlag,
    SearchIcon,
    ChevronDownIcon,
    BTC, 
    ETH, 
    USDT, 
    USDC, 
    LBTC
  },
  data() {
    return {
      window: window,
      showRemittanceForm: false,

      sendAmount: 0,
      receiveAmount: 0,
      cryptoAmount: 0,
      finalSendAmount: 0,

      sendCurrency: "",
      receiveCurrency: "",
      cryptoCurrency: "",
      transferFeeRate: 0.005,

      selectedSendCurrency: "",
      selectedReceiveCurrency: "",

      // sendingCurrencies: ["USD", "GBP", "EUR", "AUD", "ZAR", "SGD"],
      // receivingCurrencies: ["USD", "GBP", "EUR", "AUD", "ZAR", "SGD"],

      cryptoExchangeRate: 0,
      fiatExchangeRate: 0,
      unusedStuff: 0,

      validationSendAmountError: false,
      validationReceiveAmountError: false,
      validationCryptoAmountError: false,


      transferContainer: null,

      sendCurrencies: [
        {countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
      ],

      receiveCurrencies: [{countryCode: "us", currencyCode: "USD", currencyName: "United States Dollar"},
        {countryCode: "eu", currencyCode: "EUR", currencyName: "Euro"},
        {countryCode: "gb", currencyCode: "GBP", currencyName: "British Pound"},
        {countryCode: "au", currencyCode: "AUD", currencyName: "Australia Dollar"},
        {countryCode: "sg", currencyCode: "SGD", currencyName: "Singapore Dollar"},
        {countryCode: "za", currencyCode: "ZAR", currencyName: "South Africa Rand"},
        //receiving currencies
        {countryCode: "ae", currencyCode: "AED", currencyName: "United Arab Emirates Dirham"},
        {countryCode: "bh", currencyCode: "BHD", currencyName: "Bahraini Dinar"},
        {countryCode: "bn", currencyCode: "BND", currencyName: "Bruneian Dollar"},
        {countryCode: "ca", currencyCode: "CAD", currencyName: "Canadian Dollar"},
        {countryCode: "ch", currencyCode: "CHF", currencyName: "Swiss Franc"},
        {countryCode: "cn", currencyCode: "CNY", currencyName: "Chinese Yuan"},
        {countryCode: "dk", currencyCode: "DKK", currencyName: "Danish Krone"},
        {countryCode: "eg", currencyCode: "EGP", currencyName: "Egyptian Pound"},
        {countryCode: "hk", currencyCode: "HKD", currencyName: "Hong Kong Dollar"},
        {countryCode: "hu", currencyCode: "HUF", currencyName: "Hungarian Forint"},
        {countryCode: "id", currencyCode: "IDR", currencyName: "Indonesian Rupiah"},
        {countryCode: "in", currencyCode: "INR", currencyName: "Indian Rupee"},
        {countryCode: "jp", currencyCode: "JPY", currencyName: "Japanese Yen"},
        {countryCode: "kr", currencyCode: "KRW", currencyName: "South Korean Won"},
        {countryCode: "kw", currencyCode: "KWD", currencyName: "Kuwaiti Dinar"},
        {countryCode: "lk", currencyCode: "LKR", currencyName: "Sri Lankan Rupee"},
        {countryCode: "mu", currencyCode: "MUR", currencyName: "Mauritian Rupee"},
        {countryCode: "mx", currencyCode: "MXN", currencyName: "Mexican Peso"},
        {countryCode: "my", currencyCode: "MYR", currencyName: "Malaysian Ringgit"},
        {countryCode: "no", currencyCode: "NOK", currencyName: "Norwegian Krone"},
        {countryCode: "nz", currencyCode: "NZD", currencyName: "New Zealand Dollar"},
        {countryCode: "ph", currencyCode: "PHP", currencyName: "Philippine Peso"},
        {countryCode: "pl", currencyCode: "PLN", currencyName: "Polish Zloty"},
        {countryCode: "qa", currencyCode: "QAR", currencyName: "Qatari Riyal"},
        {countryCode: "ru", currencyCode: "RUB", currencyName: "Russian Ruble"},
        {countryCode: "sa", currencyCode: "SAR", currencyName: "Saudi Arabian Riyal"},
        {countryCode: "se", currencyCode: "SEK", currencyName: "Swedish Krona"},
        {countryCode: "th", currencyCode: "THB", currencyName: "Thai Baht"},
        {countryCode: "tr", currencyCode: "TRY", currencyName: "Turkish Lira"},
        {countryCode: "tw", currencyCode: "TWD", currencyName: "Taiwan New Dollar"},
        // {countryCode: "ng", currencyCode: "NGN", currencyName: "Nigerian Naira"},
        {countryCode: "br", currencyCode: "BRL", currencyName: "Brazilian Real"},
        {countryCode: "bd", currencyCode: "BDT", currencyName: "Bangladesh Taka"},
        {countryCode: "co", currencyCode: "COP", currencyName: "Colombian Peso"},
        {countryCode: "gh", currencyCode: "GHS", currencyName: "Ghanaian Cedi"},
        {countryCode: "ke", currencyCode: "KES", currencyName: "Kenyan Shilling"},
        {countryCode: "pe", currencyCode: "PEN", currencyName: "Peruvian Sol"},
        {countryCode: "tz", currencyCode: "TZS", currencyName: "Tanzanian Shilling"},
        {countryCode: "vn", currencyCode: "VND", currencyName: "Vietnamese Dong"},
        {countryCode: "zm", currencyCode: "ZMW", currencyName: "Zambian Kwacha"},
        {countryCode: "bi", currencyCode: "BIF", currencyName: "Burundian Franc"},
        {countryCode: "uy", currencyCode: "UYU", currencyName: "Uruguayan Peso"}],
      // sendCryptoCurrencies: [
      //   {cryptoCode: "BTC", cryptoName: "Bitcoin"},
      //   {cryptoCode: "ETH", cryptoName: "Ethereum"},
      //   {cryptoCode: "USDT", cryptoName: "Tether"},
      //   {cryptoCode: "USDC", cryptoName: "USD Coin"},
      // ],


      searchSendingCurrencyQuery: "",
      searchReceivingCurrencyQuery: "",

      urlPathName: "",

      debugConsole: false,

      sendingTransferFee: 0,

      amountTooSmall: false,
      invalidFormat: false,
      exchangeRateError: false,

      formReceiveLabel : "",
      formSendLabel : "",




    }
  },
  computed: {
    ...mapGetters(["userDetailsGetter", "paymentDetailsGetter","transferTypeGetter","transferTypeGetter"]),
    // sendingCurrencyList() {
    //     let countries = this.sendCurrencies;
    //     let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1)
    //     return sortedCountries.filter((item) => {
    //         return (item.currencyName.toLowerCase().includes(this.searchSendingCurrencyQuery) || item.currencyCode.toLowerCase().includes(this.searchSendingCurrencyQuery))
    //     })
    // },

    receiveCurrencyList() {
      let countries = this.receiveCurrencies;
      let sortedCountries = countries.sort((a, b) => (a.currencyCode > b.currencyCode) ? 1 : -1);
      return sortedCountries.filter((item) => {
        return (item.currencyName.toLowerCase().includes(this.searchReceivingCurrencyQuery) || item.currencyCode.toLowerCase().includes(this.searchReceivingCurrencyQuery))
      })
    },

    // transferFee() {
    //     let fee = this.sendAmount ? new BigNumber(this.sendAmount * this.transferFeeRate) : 0;
    //     return this.sendAmount > 1 ? fee.toFixed(2) : fee.toFixed(3);
    // },
    // convertedSendAmount() {
    //     let sendAmount = this.sendAmount && this.transferFee ? new BigNumber(this.sendAmount - this.transferFee) : 0;
    //     return sendAmount.toFixed(2);
    //
    // }
  },

  watch: {
    receiveAmount: debounce(function (newVal, oldVal) {
      if (Number(newVal) !== Number(oldVal)) {
        // console.log("old val not equal to new val");
        this.inputReceivingAmount();
      }
    }, 800),
    // urlPathName : function(newVal,oldVal)
    // {
    //   console.log("NewVal", newVal)
    //   console.log("OldVal", oldVal)
    //   if(newVal.indexOf('/home') >= 0)
    //   {
    //     this.submitTransferType('cash-out')
    //   }else if(newVal.indexOf('/send-money') >= 0)
    //   {
    //     this.submitTransferType('send-money')
    //   }
    // }
  },
  mounted() {
    let query = this.$route.query;
    if (this.debugConsole) console.log("this.$route.query in site transfer preparation", query)

    let url = new URL(window.location);

    this.urlPathName = url.pathname;
    if (this.debugConsole)  console.log("Url Pathname in site transfer preparation", this.urlPathName);

    if(this.urlPathName.indexOf('/home') >=0)
    {
      this.submitTransferType('cash-out');
    }
    else
    {
      this.submitTransferType('send-money');
    }

    if (this.debugConsole)  console.log("This transferTypeGetter", this.transferTypeGetter);
    this.formReceiveLabel = this.transferTypeGetter === 'cash-out' ? "You get" : 'Recipient gets';
    this.formSendLabel = this.transferTypeGetter === 'cash-out' ? 'You pay' : 'You send';


    if (this.debugConsole) console.debug("Remittance Form Mounted");
    var vm = this;

    // Close the dropdown menu if the user clicks outside of it, except when click on search currency input
    let transferPreparationContainer = document.querySelector('.trfwrapper');
    // if (this.debugConsole) console.log("Transfer Preparation container when mounted", transferPreparationContainer);

    this.transferContainer = transferPreparationContainer;
    // if (this.debugConsole) console.log("This.transferContainer", this.transferContainer);

    this.transferContainer.addEventListener('click', function (event) {
      if (this.debugConsole) console.log("Event target in remittance container", event.target);

      if (event.target.id !== "search-send-currency-input") {
        if (this.debugConsole) console.log("You are not in the search send currency input");
        document.getElementById("dropdown-menu-crypto-currency").classList.remove("show");
      }

      if (event.target.id !== "search-receive-currency-input") {
        if (this.debugConsole) console.log("You are not in the search receive currency input");
        document.getElementById("dropdown-menu-receiving-currency").classList.remove("show");
      }
    })


    let remittanceInfo = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo;
    if (this.debugConsole) console.log('Remittance Info in transfer remittance form', remittanceInfo);

    if (Object.keys(remittanceInfo).length > 0) {
      if (this.debugConsole) console.log("Remittance Info in store not empty");


      this.receiveCurrency = remittanceInfo.receiveCurrency;
      this.cryptoCurrency = remittanceInfo.cryptoCurrency || 'BTC';
      this.receiveAmount = remittanceInfo.receiveAmount;
      this.cryptoAmount = remittanceInfo.cryptoAmount;
      this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate;

      if (this.debugConsole) console.log("This.sendCurrency", this.sendCurrency);
      if (this.debugConsole) console.log("This.receiveCurrency", this.receiveCurrency);


      this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);

    } else {

      //check if query for transfer form exist
      if(query.receive_amount && query.receive_currency && query.crypto_currency)
      {
        console.log("query.receive_amount", query.receive_amount);
        console.log("query.receive_currency", query.receive_currency);
        console.log("query crypto_currency", query.crypto_currency);

        this.receiveCurrency = query.receive_currency;
        this.cryptoCurrency = query.crypto_currency;
        this.receiveAmount = query.receive_amount;

      }
      else
      {
        this.receiveCurrency = "USD";
        // this.cryptoCurrency = this.sendCryptoCurrencies && this.sendCryptoCurrencies[0].cryptoCode;
        this.cryptoCurrency = "BTC";
        let receiveAmount = '100';
        this.receiveAmount = Number(receiveAmount);

      }

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;

      this.selectedReceiveCurrency = this.receiveCurrencies && this.receiveCurrencies.find(item => item.currencyCode === this.receiveCurrency);


      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = this.receiveAmount;
      payload.crypto_ccy = this.cryptoCurrency;

     if(this.debugConsole) console.log("Payload on mounted()", payload);


      this.calculation(payload).then(res => {
        if (this.debugConsole) console.log("Calculation data in site transfer preparation", res.data);


        this.sendingTransferFee = res.data.fee;
        this.cryptoExchangeRate = res.data.crypto_exchange_rt;
        this.cryptoCurrency = res.data.crypto_ccy;
        // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(res.data.indicative_crypto_amt) : this.formatFiatPrice(res.data.indicative_crypto_amt);
        this.cryptoAmount =res.data.indicative_crypto_amt;
        
        // this.showRemittanceForm = true;
      }).catch(err => {
        if (this.debugConsole) console.log("Error in calculation in site transfer preparation", err.response)
        if (err.response.data && err.response.data.message === "receive_amt_too_small") {
          this.amountTooSmall = true;

        }
        if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => el.message === 'invalid_receive_amt' && el.path === 'receive_amt')) {
          this.invalidFormat = true;
        }

        if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate") {
          this.exchangeRateError = true;
        }
      })
    }


  },
  beforeDestroy() {

    if (this.debugConsole) console.log("This.transferContainer beforeDestroy", this.transferContainer);

    // let cryptoRemitSendingCurrency = document.querySelector('.cryptoremit-sending-currency');
    // console.log('crytpoRemitSendingCurrency', cryptoRemitSendingCurrency);

    let transferPreparationContainer = document.querySelector('.trfwrapper');
    if (this.debugConsole) console.log("Transfer preparation container in before destroy", transferPreparationContainer);


    this.transferContainer.removeEventListener('click', function (event) {
      if (this.debugConsole) console.log("Event target in remittance container", event.target);

      if (event.target.id !== "search-send-currency-input") {
        if (this.debugConsole) console.log("You are not in the search send currency input");
        document.getElementById("dropdown-menu-sending-currency").classList.remove("show");
      }

      if (event.target.id !== "search-receive-currency-input") {
        if (this.debugConsole) console.log("You are not in the search receive currency input");
        document.getElementById("dropdown-menu-receiving-currency").classList.remove("show");
      }
    })
  },
  methods: {
    ...mapActions(["getExchangeRate", "getTranscryptExchangeRate", "submitRemittanceDetails", "calculation", "submitTransferType"]),

    /* FOR NEW REMITTANCE FORM */

    inputReceivingAmount() {
      if (this.debugConsole) console.log("Receiving Amount", this.receiveAmount)
      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;


      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = Number(this.receiveAmount);
      payload.crypto_ccy = this.cryptoCurrency

      if (this.transferContainer) {
        this.$vs.loading({
          container: '#transfer-wrapper',
          scale: 0.8,
          color: 'success'
        })
      }

    if(this.debugConsole)  console.log("Payload in receiving amount", payload)

      this.calculation(payload).then(res => {
        if (this.debugConsole) console.log("Calculation Data when input receiving amount in site transfer preparation", res.data);


        this.sendingTransferFee = res.data.fee;
        this.cryptoExchangeRate = res.data.crypto_exchange_rate;
        this.cryptoCurrency = res.data.crypto_ccy;
        // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(res.data.indicative_crypto_amt) : this.formatFiatPrice(res.data.indicative_crypto_amt);
        this.cryptoAmount = res.data.indicative_crypto_amt;

        this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')
      }).catch(err => {
        if (this.debugConsole) console.log("Error in calculation data when input receiving amount in site transfer preparation", err.response);
        this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')
        if (err.response.data && err.response.data.message === "receive_amt_too_small") {
          this.amountTooSmall = true;
        }
        if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => (el.message === 'invalid_receive_amt' || el.message === 'empty') && el.path === 'receive_amt')) {
          this.invalidFormat = true;

        }
        if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate") {
          this.exchangeRateError = true;
        }
      })
    },

    inputReceivingCurrency(item) {
      this.selectedReceiveCurrency = item;
      if (this.debugConsole) console.log("This selectedReceiveCurrency", this.selectedReceiveCurrency);
      this.receiveCurrency = this.selectedReceiveCurrency.currencyCode

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;

      //TO DO change to new API to get the relevant data
      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = Number(this.receiveAmount);
      payload.crypto_ccy = this.cryptoCurrency;


      if (this.transferContainer) {
        this.$vs.loading({
          container: '#transfer-wrapper',
          scale: 0.8,
          color: 'success',
        })
      }

      //TO DO change to new API to get the relevant data
      this.calculation(payload).then(res => {
        if (this.debugConsole) console.log("Calculation data when input receiving currency", res.data);

        this.sendingTransferFee = res.data.fee;
        this.cryptoExchangeRate = res.data.crypto_exchange_rate;
        // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(res.data.indicative_crypto_amt) : this.formatFiatPrice(res.data.indicative_crypto_amt);
        this.cryptoAmount = res.data.indicative_crypto_amt;

        this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')
      }).catch(err => {
        if (this.debugConsole) console.log("Error in calculation data when input receiving currency in site transfer preparation", err.response)
        this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')
        if (err.response.data && err.response.data.message === "receive_amt_too_small") {
          this.amountTooSmall = true;
        }
        if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => el.message === 'invalid_receive_amt' && el.path === 'receive_amt')) {
          this.invalidFormat = true;

        }
        if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate") {
          this.exchangeRateError = true;
        }
      })



    },

    inputCryptoCurrency(item) {

      this.cryptoCurrency = item;

      this.amountTooSmall = false;
      this.invalidFormat = false;
      this.exchangeRateError = false;


      let payload = {}
      payload.receive_ccy = this.receiveCurrency;
      payload.receive_amt = Number(this.receiveAmount);
      payload.crypto_ccy = this.cryptoCurrency;

        if (this.transferContainer) {
          this.$vs.loading({
            container: '#transfer-wrapper',
            scale: 0.8,
            color: 'success',
          })
        }

        this.calculation(payload).then(res => {
          if (this.debugConsole) console.log("Calculation data when input receiving currency", res.data);

              this.sendingTransferFee = res.data.fee;
              this.cryptoExchangeRate = res.data.crypto_exchange_rate;
              this.cryptoCurrency = res.data.crypto_ccy;
              // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(res.data.indicative_crypto_amt) : this.formatFiatPrice(res.data.indicative_crypto_amt);
              this.cryptoAmount = res.data.indicative_crypto_amt;

              this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')

        }).catch(err => {
            if (this.debugConsole) console.log("Error in calculation data when input receiving currency in site transfer preparation", err.response)
            this.$vs.loading.close('#transfer-wrapper > .con-vs-loading')
            if (err.response.data && err.response.data.message === "receive_amt_too_small") {
              this.amountTooSmall = true;
            }
            if (err.response.data && err.response.data.errors && err.response.data.errors.find(el => el.message === 'invalid_receive_amt' && el.path === 'receive_amt')) {
              this.invalidFormat = true;

            }
            if (err.response.data && err.response.data.message === "no_exchange_rate" || err.response.data && err.response.data.message === "no_crypto_exchange_rate") {
              this.exchangeRateError = true;
            }
          })
    },


    toggleCryptoCurrencyOptions() {
      document.getElementById('dropdown-menu-crypto-currency').classList.toggle('show');

    },

    toggleReceiveCurrencyOptions() {
      document.getElementById("dropdown-menu-receiving-currency").classList.toggle('show');
      let classList = document.getElementById("dropdown-menu-receiving-currency").classList
      if (this.debugConsole) console.log('Class List', classList);

      document.getElementById("search-receive-currency-input").focus();

    },

    submitForm() {


      //validation
      // if (!this.sendAmount || this.sendAmount && (Number(this.sendAmount) <= 0.00 || isNaN(this.sendAmount))) {
      //     this.validationSendAmountError = true;
      // } else {
      //     this.validationSendAmountError = false;
      // }

      if (!this.receiveAmount || this.receiveAmount && (Number(this.receiveAmount) <= 0.00 || isNaN(this.receiveAmount))) {
        this.validationReceiveAmountError = true;
      } else {
        this.validationReceiveAmountError = false;
      }

      if (!this.cryptoAmount || this.cryptoAmount && (Number(this.cryptoAmount) <= 0.00)) {
        this.validationCryptoAmountError = true;
      } else {
        this.validationCryptoAmountError = false;
      }

      if (this.validationCryptoAmountError || this.validationReceiveAmountError) {
        return;
      }

      let data = {}
      data.sendAmount = Number(this.sendAmount);
      data.sendCurrency = this.sendCurrency;
      data.cryptoAmount = Number(this.cryptoAmount);
      data.receiveAmount = Number(this.receiveAmount);
      data.receiveCurrency = this.receiveCurrency;
      data.convertedSendAmount = Number(this.convertedSendAmount);
      data.fiatExchangeRate = Number(this.fiatExchangeRate);
      data.cryptoExchangeRate = Number(this.cryptoExchangeRate);
      data.finalSendAmount = Number(this.finalSendAmount);
      data.transferFee = Number(this.transferFee);
      data.cryptoCurrency = this.cryptoCurrency;


      if (this.debugConsole) console.log("Data", data);
      this.$store.commit("UPDATE_REMITTANCE_INFO", data);

      if (this.userDetailsGetter && this.userDetailsGetter.userToken) {
        this.$router.push('/transfer/remittance-form')
      } else {
        this.$router.push('/account/register');
      }


    },

    /*DEPRECATED*/

    changeSendingAmount() {
      if (this.debugConsole) console.log("Change Sending Amount called");
      if (this.debugConsole) console.log("Sending Amount", this.sendAmount);

      let finalSendAmount = new BigNumber(this.sendAmount - this.transferFee);
      this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
      if (this.debugConsole) console.log("Final Send Amount when sending amount changed", this.finalSendAmount);

      let receiveAmount = new BigNumber(this.finalSendAmount * this.fiatExchangeRate);
      this.receiveAmount = receiveAmount.toFixed(2);
      if (this.debugConsole) console.log("Receiving Amount when sending amount changed", this.receiveAmount);

      let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
      this.cryptoAmount = cryptoAmount.toFixed(8);
      if (this.debugConsole) console.log("Crypto amount when sending amount changed", this.cryptoAmount);


    },

    changeReceivingAmount() {
      if (this.debugConsole) console.log('Change Receiving Amount called');
      if (this.debugConsole) console.log("Receiving Amount", this.receiveAmount);

      let finalSendAmount = new BigNumber(this.receiveAmount / this.fiatExchangeRate);
      this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
      if (this.debugConsole) console.log('Final Send Amount when receiving Amount changed', this.finalSendAmount);

      // let sendAmount = new BigNumber(this.receiveAmount / this.fiatExchangeRate);
      // this.sendAmount = sendAmount.toFixed(2);
      // console.log('Sending Amount when receiving Amount changed', this.sendAmount);

      let sendAmount = new BigNumber(Number(this.finalSendAmount) / 0.995)
      this.sendAmount = sendAmount.toFixed(2);
      if (this.debugConsole) console.log('Sending Amount when receiving Amount changed', this.sendAmount);

      let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
      this.cryptoAmount = cryptoAmount.toFixed(8);
      if (this.debugConsole) console.log("Crypto Amount when receiving amount changed", this.cryptoAmount);
    },

    selectSendCurrency(item) {
      if (this.debugConsole) console.log("Send Currency Item", item)
      this.selectedSendCurrency = item;
      if (this.debugConsole) console.log("selectedSendCurrency", this.selectedSendCurrency);

      this.sendCurrency = this.selectedSendCurrency.currencyCode;

      let cryptoData = {};
      cryptoData.fromCurrency = this.cryptoCurrency;
      cryptoData.toCurrency = this.sendCurrency;

      if (this.debugConsole) console.log("cryptoData", cryptoData);

      this.getTranscryptExchangeRate(cryptoData).then((res) => {
        let cryptoExchangeRate = new BigNumber(res.data.exchange_rate);
        this.cryptoExchangeRate = cryptoExchangeRate.toFixed(2);
        if (this.debugConsole) console.log("Crytpo Exchange Rate (change sending currency)", this.cryptoExchangeRate);

        let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
        this.cryptoAmount = cryptoAmount.toFixed(8);
        if (this.debugConsole) console.log("Crypto Amount (change sending currency)", this.cryptoAmount);
      }, error => {
        if (this.debugConsole) console.log("Error in cryptoData Exchange Rate", error);
      })

      let fiatData = {};
      fiatData.fromCurrency = this.sendCurrency;
      fiatData.toCurrency = this.receiveCurrency;

      if (this.debugConsole) console.log("FiatData", fiatData);


      this.getTranscryptExchangeRate(fiatData).then((res) => {
        let fiatExchangeRate = new BigNumber(res.data.exchange_rate);
        this.fiatExchangeRate = fiatExchangeRate.toFixed(2);
        if (this.debugConsole) console.log("Fiat Exchange Rate (change sending currency)", this.fiatExchangeRate);

        let finalSendAmount = new BigNumber(this.sendAmount - this.transferFee)
        this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
        if (this.debugConsole) console.log("Final send Amount (change sending Currency)", this.finalSendAmount);

        let receiveAmount = new BigNumber(this.finalSendAmount * this.fiatExchangeRate);
        this.receiveAmount = receiveAmount.toFixed(2);
        if (this.debugConsole) console.log("Receive Amount (change sending currency)", this.receiveAmount);
      }, error => {
        if (this.debugConsole) console.log("Error in fiatData Exchange Rate", error);
      })
    }
    ,

    selectReceiveCurrency(item) {
      if (this.debugConsole) console.log("Receive currency Item", item);
      this.selectedReceiveCurrency = item;
      if (this.debugConsole) console.log("selectedReceivedCurrency", this.selectedReceiveCurrency);

      this.receiveCurrency = this.selectedReceiveCurrency.currencyCode;

      if (this.debugConsole) console.log("Change Receiving currency value");
      if (this.debugConsole) console.log("Receive Currency val", this.receiveCurrency);

      let data = {};
      data.fromCurrency = this.sendCurrency;
      data.toCurrency = this.receiveCurrency;

      this.getExchangeRate(data).then((res) => {
        let fiatExchangeRate = new BigNumber(res.data.exchange_rate);
        this.fiatExchangeRate = fiatExchangeRate.toFixed(2);
        if (this.debugConsole) console.log("Fiat Exchange Rate (change receiving currency)", this.fiatExchangeRate)
        if (this.debugConsole) console.log("Receive Amount (change receiving currency)", this.receiveAmount);

        let finalSendAmount = new BigNumber(this.receiveAmount / this.fiatExchangeRate);
        this.finalSendAmount = this.sendAmount > 1 ? finalSendAmount.toFixed(2) : finalSendAmount.toFixed(3);
        if (this.debugConsole) console.log("Final Send Amount (change receiving currency", this.finalSendAmount);


        let sendAmount = new BigNumber(Number(this.finalSendAmount) / 0.995);
        this.sendAmount = sendAmount.toFixed(2);
        if (this.debugConsole) console.log("Sending Amount (change receiving currency) ", this.sendAmount);

        if (this.debugConsole) console.log("cryptoexchangerate (change receiving currency)", this.cryptoExchangeRate);

        let cryptoAmount = new BigNumber(this.sendAmount / this.cryptoExchangeRate);
        this.cryptoAmount = cryptoAmount.toFixed(8);
        if (this.debugConsole) console.log('Crypto Amount (change receiving currency)', this.cryptoAmount);
      })
    },

    toggleSendCurrencyOptions() {
      // event.stopPropagation();
      document.getElementById("dropdown-menu-sending-currency").classList.toggle('show');
      let classList = document.getElementById("dropdown-menu-sending-currency").classList
      if (this.debugConsole) console.log('Class List', classList);

      document.getElementById("search-send-currency-input").focus();

    },


  },
}
</script>
<style>


</style>

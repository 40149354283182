<template>
    <div>
        <header class="header-one">
            <!-- header-area start -->
            <div id="sticker" class="header-area hidden-xs">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="row">
                                <!-- logo start -->
                                <div class="col-md-4 col-sm-3">
                                    <div class="logo">
                                        <!-- Brand -->
                                        <router-link to="/home" class="navbar-brand page-scroll white-logo">
                                            <img src="/assets/remittance-assets/img/transcrypt-logo-white.png" alt="transcrypt-logo" class="pt-1">
                                        </router-link>
                                        <router-link to="/home" class="navbar-brand page-scroll black-logo">
                                            <img src="/assets/remittance-assets/img/transcrypt-logo-colour.png" alt="transcrypt-logo" class="pt-1">
                                        </router-link>
                                    </div>
                                    <div class="logo" v-if="showCollaborationLogo">
                                    <!-- Brand -->
                                    <a href="https://saverasia.com/au/" target="_blank" rel="noreferrer noopenner" class="collaboration-logo navbar-brand page-scroll white-logo">
                                      <img :src="`/assets/remittance-assets/${collaborationLogo}.png`" alt="" style="padding-left:15px;border-left:1px solid #ffffff">
                                    </a>
                                  </div>
                                    <!-- logo end -->
                                </div>
                                <div class="col-md-8 col-sm-9">
                                    <div :class="[this.userDetailsGetter && this.userDetailsGetter.userToken ? 'header-right-link-login' : 'header-right-link']">
                                        <!-- search option end -->
                                        <!--                                        <a class="s-menu" href="login.html">Login</a>-->
                                        <router-link v-if="this.userDetailsGetter && !this.userDetailsGetter.userToken"
                                                     to="/account/register" class="s-menu" :class="[ urlPathName === '/home' ? 'gtm-sign-up' : 'gtm-sign-up-send-money']">Sign up
                                        </router-link>
                                    </div>
                                    <div :class="[this.userDetailsGetter && this.userDetailsGetter.userToken ? 'header-right-link-login' : 'header-right-link']">
                                        <!-- search option end -->
                                        <!--                                        <a class="s-menu" href="login.html">Login</a>-->
                                        <router-link v-if="this.userDetailsGetter && !this.userDetailsGetter.userToken"
                                                     to="/account/login" class="s-menu-login-btn" :class="[urlPathName === '/home' ? 'gtm-login' : 'gtm-login-send-money']">Login
                                        </router-link>
                                        <router-link v-else to="/dashboard" class="s-menu">Dashboard</router-link>
                                    </div>
                                    <!-- mainmenu start -->
                                    <nav class="navbar navbar-default">
                                        <div class="collapse navbar-collapse" id="navbar-example">
                                            <div class="main-menu">
                                                <ul class="nav navbar-nav navbar-right">
                                                    <li>
                                                        <router-link :to="extraMenuLink">{{extraMenu}}</router-link>
                                                    </li>
                                                  <!-- <li>
                                                    <router-link to="/trading-home">Invest</router-link>
                                                  </li> -->
                                                    <li>
                                                        <router-link to="/about">About</router-link>
                                                    </li>
                                                    <li>
                                                        <router-link to="/support">Support</router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                    <!-- mainmenu end -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- header-area end -->
            <!-- mobile-menu-area start -->
            <div class="mobile-menu-area hidden-lg hidden-md hidden-sm">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mobile-menu">
                                <div class="logo">
                                    <a>
                                        <router-link to="/home">
                                            <img src="/assets/remittance-assets/img/transcrypt-logo-colour.png" alt=""
                                                 class="m-1">
                                        </router-link>
                                    </a>
                                </div>
                                <nav id="dropdown">
                                    <ul class="">
                                        <li style="list-style-type: none">
                                            <router-link
                                                    v-if="this.userDetailsGetter && !this.userDetailsGetter.userToken"
                                                    to="/account/login" :class="[urlPathName === '/home' ? 'gtm-login' : 'gtm-login-send-money']">Login
                                            </router-link>
                                            <router-link v-else to="/dashboard">Dashboard</router-link>
                                        </li>
                                        <li style="list-style-type: none">
                                            <router-link
                                                    v-if="this.userDetailsGetter && !this.userDetailsGetter.userToken"
                                                    to="/account/register"
                                                    :class="[ urlPathName === '/home' ? 'gtm-sign-up' : 'gtm-sign-up-send-money']"
                                            >Sign up
                                            </router-link>
                                        </li>
                                        <li style="list-style-type: none">
                                          <router-link :to="extraMenuLink">{{extraMenu}}</router-link>
                                        </li>
                                        <!-- <li style="list-style-type: none">
                                          <router-link to="/trading-home">Invest</router-link>
                                        </li> -->
                                        <li style="list-style-type: none">
                                            <router-link to="/about">About</router-link>
                                        </li>
                                        <li style="list-style-type: none">
                                            <router-link to="/support">Support</router-link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile-menu-area end -->
        </header>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'homepageHeader',
        data() {
            return {
                urlPathName : "",
                showCollaborationLogo : false,
                collaborationLogo : "",
                extraMenu : "",
                extraMenuLink : "",
            }
        },
        mounted() {
            let url = new URL(window.location);

            this.urlPathName = url.pathname;
            // console.log("Url pathname in header", this.urlPathName);

          //check the URL query string (query string set from Google or FB)
          let query = this.$route.query;
          if (this.debugConsole) console.log("this.$route.query", query)


          if (Object.keys(query).length > 0) {
            if(query.logo)
            {
              this.showCollaborationLogo = true;
              this.collaborationLogo = query.logo;
              // console.log("CollaborationLogo", this.collaborationLogo);
            }else
            {
              this.showCollaborationLogo = false;
              this.collaborationLogo = "";
            }
          }

          //check if the url pathname is /send-money
          if(this.urlPathName.indexOf('/send-money') >= 0 )
          {
            this.extraMenu = 'Cash Out';
            this.extraMenuLink = '/home';
          }else
          {
            this.extraMenu = 'Send Money';
            this.extraMenuLink = '/send-money';
          }

        },
        methods: {},
        computed: {
            ...mapGetters(["userDetailsGetter"]),

        },
    }
</script>
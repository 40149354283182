<template>
    <div>
        <!-- Start Footer Area -->
        <footer class="footer-homepage">
            <div class="footer-area">
                <div class="container">
<!--                    <div class="row mb-5">-->
<!--                        <div class="col-md-4 col-sm-4 col-lg-4">-->
<!--                           <div class="text-center pointer" @click="goToAbout()">About</div>-->
<!--                        </div>-->
<!--                        <div class="col-md-4 col-sm-4">-->
<!--                           <div class="text-center pointer" @click="goToSupport()">Support</div>-->
<!--                        </div>-->
<!--                        <div class="col-md-4 col-sm-4">-->
<!--                           <div class="text-center pointer" @click="goToPrivacy()">Privacy & terms</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-lg-3 text-center">
                            <div class="row text-center" data-class="text-md-footer-left">
                                <img src="/assets/remittance-assets/img/transcrypt-logo-white.png" class="p-2" alt="" width="300px">
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-6 text-center">
                            <div class="row mt-3">
                                <div class="col-md-4 col-sm-4 col-lg-4">
                                    <div class="text-center pointer" @click="goToAbout()">About</div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-lg-4">
                                    <div class="text-center pointer" @click="goToSupport()">Support</div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-lg-4">
                                    <div class="text-center pointer" @click="goToPrivacy()">Privacy & terms</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-3 text-left">
                            <div class="row mt-2 text-center" data-class="text-md-footer-right">
                                 <span class="m-2">Connect with us
                                   <a
                                         href="https://www.facebook.com/TransCryptGlobal" target="_blank"
                                         rel="noopener noreferrer"><img
                                         src="/assets/remittance-assets/transcrypt-facebook.png" alt="" class="m-1"></a>
                                  <a
                                      href="https://g.page/TransCrypt?gm" target="_blank"
                                      rel="noopener noreferrer"><img
                                      src="/assets/remittance-assets/transcrypt-google-business-listing.png" alt="" class="m-1"></a>
                                 <a
                                     href="https://twitter.com/transcryptglo" target="_blank"
                                     rel="noopener noreferrer"><img
                                     src="/assets/remittance-assets/transcrypt-twitter.png" alt="" class="m-1"></a></span>
                            </div>

                        </div>

                    </div>
                    <hr>
                    <div class="row text-center mt-3 mb-2">
                        © TransCrypt 2021. All Rights Reserved
                    </div>
                    <div class="row text-center m-2">
                        TransCrypt is an affiliate of <a href="https://triple-a.io/" class="text-white">TripleA</a>.
                    </div>
                    <div class="row text-center m-2">
                        Triple-A holds licenses from the Monetary Authority of Singapore (MAS), Banque de France's ACPR in Europe, and is registered with the United States Financial Crimes Enforcement Network(FinCEN).
                    </div>

                </div>
            </div>
        </footer>
        <!-- End Footer Area -->
    </div>
</template>
<script>


    export default {
        name: 'support',
        data() {
            return {
                name: "",
                email: "",
                message: "",

                validationContactSupportError: false,
                contactSupportSuccessful: false,
                contactSupportUnsuccessful: false,

            }
        },
        mounted() {


        },
        methods: {

            goToAbout()
            {
                this.$router.push('/about').catch(() => {
                });
            },
            goToPrivacy(){
                this.$router.push('/privacy-and-terms-policy').catch(() => {
                })
            },
            goToSupport(){
                this.$router.push('/support').catch(() => {
                });
            }
        }
    }
</script>